import React, { createContext, useMemo, useEffect, useState, useContext } from 'react';
import propTypes from 'prop-types';

import managers from 'managers';
import config from 'config';
import useToast from 'hooks/toasts/useToast';
import useQuery from 'hooks/router/useQuery';
import { ClientContext } from 'context/Client';

export const SessionContext = createContext({ isLoading: true, hasSession: false, profile: {} });

export const SessionContainer = ({ children }) => {
    const { loginUrl } = useContext(ClientContext);
    const query = useQuery();

    const [hasSession, setHasSession] = useState(false);
    const [profile, setProfile] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [auth, setAuth] = useState(null);

    const { notify } = useToast({});

    useEffect(() => {
        const getToken = async () => {
            try {
                await managers.tokenManager.getTokenFromCode(query.code, config.SELF_URL);
            } catch (e) {
                console.error(e);
            }

            setAuth(true);
        };

        if (!auth && query.code) {
            getToken();
        } else {
            setAuth(true);
        }
    }, []);

    useEffect(() => {
        const getCurrentSession = async () => {
            try {
                await managers.sessionManager.getSession();

                setHasSession(true);
            } catch (e) {
                if (e.response?.status === config.HTTP_CODES.UNAUTHORIZED) {
                    window.location.replace(loginUrl);
                    setHasSession(false);
                } else {
                    notify(e?.response?.data?.message);
                }

                setIsLoading(false);
            }
        };

        if (auth !== null) {
            if (!hasSession) {
                getCurrentSession();
            }
        }
    }, [query, auth]);

    useEffect(() => {
        const getProfile = async () => {
            try {
                const { data } = await managers.profileManager.getProfile();

                setProfile(data);
            } catch (e) {
                notify(e?.response?.data?.message);
            }

            setIsLoading(false);
        };

        if (hasSession) {
            getProfile();
        }
    }, [hasSession]);

    const contextValue = useMemo(
        () => ({
            isLoading,
            hasSession,
            profile,
        }),
        [isLoading, hasSession, profile]
    );

    return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};

SessionContainer.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    children: propTypes.any,
};

export default SessionContainer;
