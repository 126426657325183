import config from 'config';

const logoutManager = {
    getLogoutUrl({ selfUrl, domainId }) {
        const url = `${config.BASE_URL}/auth/logout`;
        const query = new URLSearchParams({
            redirectUri: encodeURIComponent(selfUrl),
            ...(domainId && { domainId }),
        });

        return `${url}?${query}`;
    },
};

export default logoutManager;
