import http from './http';

const getTokenFromCode = (code, redirectUri) => {
    return http.get('public/auth/token', {
        params: {
            code,
            redirectUri,
        },
    });
};

export default {
    getTokenFromCode,
};
