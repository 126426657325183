import React, { createContext, useMemo } from 'react';
import config from 'config';
import propTypes from 'prop-types';
import loginManager from 'managers/loginManager';
import logoutManager from 'managers/logoutManager';
import useQuery from 'hooks/router/useQuery';

export const ClientContext = createContext({ loginUrl: '', logoutUrl: '' });

export const ClientContainer = ({ children }) => {
    const query = useQuery();
    const params = {
        selfUrl: config.SELF_URL,
        ...(query.domain_id && { domainId: query.domain_id }),
    };

    const logoutUrl = logoutManager.getLogoutUrl(params);
    const loginUrl = loginManager.getLoginUrl(params);

    const contextValue = useMemo(
        () => ({
            loginUrl,
            logoutUrl,
        }),
        []
    );

    return <ClientContext.Provider value={contextValue}>{children}</ClientContext.Provider>;
};

ClientContainer.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: propTypes.array.isRequired,
};

export default ClientContainer;
