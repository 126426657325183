import React from 'react';
import { useOutletContext, Outlet, Navigate } from 'react-router-dom';
import propTypes from 'prop-types';

const PrivateRoute = ({ element }) => {
    const session = useOutletContext();
    return session.hasSession ? element || <Outlet /> : <Navigate to="/" replace />;
};

PrivateRoute.propTypes = {
    element: propTypes.element.isRequired,
};

export default PrivateRoute;
