import React from 'react';
import { useOutletContext, Outlet, Navigate } from 'react-router-dom';
import propTypes from 'prop-types';

const ProtectedRoute = ({ element }) => {
    const session = useOutletContext();
    return session.hasSession ? <Navigate to="/profile" replace /> : element || <Outlet />;
};

ProtectedRoute.propTypes = {
    element: propTypes.element.isRequired,
};

export default ProtectedRoute;
