import React from 'react';

const NotFound = () => {
    return (
        <div className="not-found">
            <header className="not-found__header">
                <h1>404 : Page not found</h1>
            </header>
        </div>
    );
};

export default NotFound;
