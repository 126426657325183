import React from 'react';
import { Route } from 'react-router-dom';
import Profile from 'pages/Profile';
import Home from 'pages/Home';
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from 'routes/PublicRoute';
import NotFound from 'pages/NotFound';

export const routes = {
    home: {
        path: '/',
        element: <Home />,
        type: 'public',
    },
    profile: {
        path: '/profile',
        element: <Profile />,
        type: 'private',
    },
    notFound: {
        path: '*',
        element: <NotFound />,
        type: 'public',
    },
};

export const getRoutes = () => {
    const routesArray = Object.values(routes);

    return routesArray.map((route) => {
        if (route.type === 'private') {
            return <Route key={route.path} path={route.path} element={<PrivateRoute element={route.element} />} />;
        }

        return <Route key={route.path} path={route.path} element={<PublicRoute element={route.element} />} />;
    });
};

export default { getRoutes, routes };
