const config = {
    SELF_URL: process.env.REACT_APP_SELF_URL || process.env.CLIENT_VIRTUAL_HOST,
    HTTP_CODES: {
        SUCCESS: 200,
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        FORBIDDEN: 403,
        NOT_FOUND: 404,
        WRONG_METHOD: 405,
        CONFLICT: 409,
        INTERNAL: 500,
    },
    BASE_URL: process.env.REACT_APP_BASE_URL || '',
};

export default config;
