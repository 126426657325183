import React from 'react';

const Home = () => {
    return (
        <div className="home">
            <header className="home__header">
                <p>No session found, you will be redirected to login page</p>
            </header>
        </div>
    );
};

export default Home;
