import React, { useContext } from 'react';
import { ClientContext } from 'context/Client';
import { SessionContext } from 'context/Session';

const Profile = () => {
    const { logoutUrl } = useContext(ClientContext);
    const { profile } = useContext(SessionContext);

    return (
        <div className="profile">
            <header className="profile__header">
                <h2>User data</h2>
                <p>_id : {profile.id}</p>
                <p>First name : {profile.firstName}</p>
                <p>Last name : {profile.lastName}</p>
                <h2>Merchant data</h2>
                <p>Merchant name : {profile.merchantName}</p>
                <p>Slug : {profile.slug}</p>
                <a className="profile__link" href={logoutUrl}>
                    Logout
                </a>
            </header>
        </div>
    );
};

export default Profile;
