import React, { Fragment, useContext } from 'react';
import './App.css';
import { getRoutes } from 'setupRouter';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ClientContainer } from 'context/Client';
import { SessionContainer, SessionContext } from 'context/Session';
import config from 'config';
import Loading from 'components/Loading';

const Main = () => {
    const session = useContext(SessionContext);

    if (session.isLoading) {
        return <Loading />;
    }

    return <Outlet context={session} />;
};

const App = () => {
    const routes = getRoutes();
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                element={
                    <ClientContainer>
                        <SessionContainer>
                            <Main />
                        </SessionContainer>
                    </ClientContainer>
                }
            >
                {routes}
            </Route>
        ),
        {
            basename: config.BASE_URL,
        }
    );

    return (
        <>
            <ToastContainer />
            <RouterProvider router={router} />
        </>
    );
};

export default App;
