import { toast } from 'react-toastify';

function useToast({ duration = 2000 }) {
    const notify = (message) => {
        toast(message, { autoClose: duration });
    };

    return { notify };
}

export default useToast;
