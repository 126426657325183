import config from 'config';

const loginManager = {
    getLoginUrl({ selfUrl, domainId }) {
        const url = `${config.BASE_URL}/auth/login`;
        const query = new URLSearchParams({
            redirectUri: encodeURIComponent(selfUrl),
            ...(domainId && { domainId }),
        });

        return `${url}?${query}`;
    },
};

export default loginManager;
